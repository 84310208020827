body {
    color: $grey;
}
label {
    color: $dark;
    font-weight: bold;
}
.text-purple {
    color: $purple;
}
.text-dark {
    color: $dark !important;
}
@each $color, $i in $colors {
    .text-#{$color} {
        color: $i;
    }
}
@each $color, $i in $colors {
    $opacityNum: 0;
    $opacityName: 0;
    @while $opacityName < 10 {
        .text-#{$color}-o#{$opacityName} {
            color: rgba($i, $opacityNum);
        }
        $opacityNum: $opacityNum + 0.1;
        $opacityName: $opacityName + 1;
    }
}
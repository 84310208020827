@each $color, $i in $colors {
    .bg-#{$color} {
        background-color: $i;
    }
}
@each $color, $i in $colors {
    $opacityNum: 0;
    $opacityName: 0;
    @while $opacityName < 10 {
        .bg-#{$color}-o#{$opacityName} {
            background-color: rgba($i, $opacityNum);
        }
        $opacityNum: $opacityNum + 0.1;
        $opacityName: $opacityName + 1;
    }
}
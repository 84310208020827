.milestone-list{
    max-height: 300px;
    overflow-y: auto;
}
.step-lines-head{
    position: relative;
    &:before{
        content: '';
        position: absolute;
        left: -1px;
        top: 50%;
        transform: translateX(-50%);
        width: 3px;
        height: 50%;
        background-color: $blue;
    }
}
.step-lines{
    position: relative;
    &:before{
        content: '';
        position: absolute;
        left: 0;
        top: -10px;
        width: 3px;
        height: 110%;
        background-color: $blue;
    }
}
.step-lines-end{
    position: relative;
    &:before{
        content: '';
        position: absolute;
        left: 1px;
        bottom: 50%;
        transform: translateX(-50%);
        width: 3px;
        height: 50%;
        background-color: $blue;
    }
}
.step-line {
    position: relative;
    &:before{
        content: '';
        display: block;
        position: absolute;
        left: 1.3rem;
        bottom: -1.5rem;
        width: 3px;
        height: 1.5rem;
        background-color: $blue;
    }
}
.step-line-end {
    position: relative;
    &:before{
        content: '';
        display: block;
        position: absolute;
        left: 1.3rem;
        bottom: -1.5rem;
        width: 3px;
        height: 1.5rem;
        background-color: $blue;
    }
    &:after{
        content: '\f078';
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        font-size: 0.8rem;
        text-rendering: auto;
        position: absolute;
        left: .7rem;
        bottom: -2rem;
        transform: translateX(50%);
        color: $blue;
    }
}
a.time-management-list{
    color: $dark;
    &:hover{
        text-decoration: none;
    }
}

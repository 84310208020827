.k-card {
    .k-card-image-wrap {
        height: 180px;
        .k-caption {
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0;
            padding: 0.5rem 0.8rem;
            background-color: rgba($blue, 0.4);
            color: $light;
        }
        .k-card-image {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            max-width: 360px;
        }
    }
    &:hover {
        .k-caption {
            background-color: rgba($blue, 0.7);
        }
    }
}
.k-card-rounded {
    border-radius: $border-radius-regular;
    overflow: hidden;
}
.k-card-sm-rounded {
    border-radius: $border-radius-small;
    overflow: hidden;
}

.footer-app-apeh{
    position: fixed;
    left: 1rem; 
    bottom: 1rem;
    @include max-width-sm(){
        position: relative;
        bottom: inherit;
    }
    small{
        margin-bottom: .3rem;
    }
}
@media all and (min-width: 800px){
    .footer-app-apeh{
        opacity: .5;
        transition: $transition-02s;
        small{
            opacity: 0;
        }
        &:hover{
            opacity: 1;
            small{
                opacity: 1;
            }
        }
    }

}

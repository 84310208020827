.end-align-to-right {
    & > *:last-child {
        text-align: right;
    }
}

.galleries-div {
    width: 8em;
    height: 8em;
    border-radius: 6px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: 3px 3px 7px #c0c0c0;
    margin-bottom: 0.8rem;
}

button.remove-pic {
    border: rgb(var(--danger)) solid 1px;
    border-radius: 50px;
    background: var(--danger);
    color: #fff;
    font-weight: bold;
    padding: 0px 0.5em;
    float: right;
}

.search-galleries-desc {
    width: 8em;
    font-weight: bold;
    vertical-align: bottom;
    color: rgb(var(--primary));
    cursor: pointer;
    &:hover {
        color: rgb(var(--danger));
    }
}
.w-8em {
    width: 8em;
}

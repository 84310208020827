.k-chart-horizon-bar {
    overflow: hidden;
    border-radius: $border-radius-small;
    & > .track {
        height: 1.4rem;
    }
}
$axis-shadow: 0 0.1rem 0.5rem rgba($blue, 0.6);
.k-chart-sticky-axis {
    width: 0.16rem;
    top: 1.4rem;
    bottom: 0;
    position: absolute;
    background-color: $blue;
    z-index: 1;
    box-shadow: $axis-shadow;
    &::before {
        display: block;
        content: " ";
        width: 0.6rem;
        height: 0.6rem;
        background-color: $blue;
        border-radius: 100%;
        margin-top: -0.25rem;
        margin-left: -0.2rem;
        box-shadow: $axis-shadow;
    }
}
.stick-label-right{
    position: absolute;
    right: -130px;
}
.stick-label-left{
    position: absolute;
    right: 0;
    width: 120px;
}

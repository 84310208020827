.contract-text{
    p{
        padding: 0 .2rem;
        border-radius: $border-radius-small;
        border: 1px solid transparent;
        &:hover{
            background-color: rgba($yellow, .3);
            border: 1px solid rgba($yellow, .5);
            cursor: pointer;
        }
        &.saved{
            background-color: rgba($blue, .2);
            border: 1px solid rgba($blue, .3);
            &:hover{
                background-color: rgba($blue, .3);
                border: 1px solid rgba($blue, .5);
            }
        }
    }
}
.kuaitshiu-on-paper{
    @media all and (min-width: 1401px){
        max-width: 600px;
        margin-left: 34%;
    }
    @media all and (max-width: 1400px){
        max-width: 500px;
        margin-left: 34%;
    }
    @media all and (max-width: 1300px){
        max-width: 450px;
        margin-left: 25%;
    }
    @media all and (max-width: 1200px){
        max-width: 400px;
        margin-left: 25%;
    }
    @media all and (max-width: 950px){
        max-width: 350px;
        margin-left: 20%;
    }
    @media all and (max-width: 900px){
        max-width: 325px;
        margin-left: 15%;
    }
    @media all and (max-width: 800px){
        max-width: 300px;
        margin: 1rem auto;
    }
}
.bg-building{
    background-image: url('/assets/images/bg-building.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top;
    @include max-width-sm(){
        height: 25vh;
    }
}
.login-footer{
    @media all and (min-width: 800px){
        .footer-app-apeh{
            color: $light;
        }
    }
}
.btn{
    border-radius: $border-radius-regular;
    padding: $padding-regular;
    font-weight: bold;
}
.button-primary,
.btn-primary {
    color: $light;
    background-color: $blue;
    &.k-btn-primary-o1 {
        color: $dark;
        background-color: rgba($blue, 0.1);
    }
    &:hover {
        color: $light;
        background-color: darken($blue, 10);
    }
}
.button-danger {
    color: $light;
    background-color: $danger;
    padding: $padding-regular;
    border-radius: $border-radius-regular;
    font-weight: bold;
    &:hover {
        color: $light;
        background-color: darken($danger, 10);
    }
}
.button-outline-secondary {
    padding: $padding-regular;
    border-radius: $border-radius-regular;
    background-color: transparent;
    border: $border-size-regular solid $grey-dark;
    color: $grey-dark;
    font-weight: bold;
    opacity: 0.5;
    transition: $transition-02s;
    &:hover {
        opacity: 1;
    }
}
.button-outline-danger {
    padding: $padding-regular;
    border-radius: $border-radius-regular;
    background-color: transparent;
    border: $border-size-regular solid rgba($danger, 0.4);
    font-weight: bold;
    &:hover {
        border: $border-size-regular solid rgba($danger, 0.7);
    }
}
.button-outline-primary {
    padding: $padding-regular;
    border-radius: $border-radius-regular;
    background-color: transparent;
    border: $border-size-regular solid rgba($blue, 0.4);
    font-weight: bold;
    color: $blue;
    &:hover {
        border: $border-size-regular solid rgba($blue, 0.7);
        color: $blue;
    }
}
.btn-line-notify {
    padding: $padding-regular;
    border-radius: $border-radius-regular;
    background-color: #00c300;
    color: #fff;
    &:hover {
        background-color: #00b300;
        color: #fff;
    }
    img {
        width: 1rem;
        height: 1rem;
    }
}
.btn-circle {
    width: 38px;
    height: 38px;
    border-radius: 100%;
    background-color: $light;
    opacity: 0.7;
    font-size: 1.2rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.hover-danger {
    &:hover {
        color: $light;
        background-color: $danger;
    }
}
.hover-primary {
    &:hover {
        color: $light;
        background-color: $blue;
    }
}
.text-hover-primary{
    &:hover{
        color: $primary !important;
        text-decoration: none;
    }
}
.text-hover-danger{
    &:hover{
        color: $danger !important;
        text-decoration: none;
    }
}
.cursor-default{
    cursor: default !important;
}
.input-editable {
    cursor: text;
    &:hover{
        color: $blue;
    }
}
label[name="folder_name"] {
    vertical-align: sub;
}
.nestable-item-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 10px;
}
.folder-template-list{
    .nestable-item-content {
        border-bottom: 1px solid rgba($blue, .2);
    }
}
.nestable-rtl .nestable-list {
    padding: 0 40px 0 0;
}
.nestable>.nestable-list {
    padding: 0;
}
.nestable-item,
.nestable-item-copy {
    margin: 10px 0 0;
}
.nestable-item:first-child,
.nestable-item-copy:first-child {
    margin-top: 0;
}
.nestable-item .nestable-list,
.nestable-item-copy .nestable-list {
    margin-top: 10px;
}
.nestable-item {
    position: relative;
}
.nestable-item.is-dragging .nestable-list {
    pointer-events: none;
}
.nestable-item.is-dragging * {
    opacity: 0;
    filter: alpha(opacity=0);
}
.nestable-item.is-dragging:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(106, 127, 233, 0.274);
    border: 1px dashed rgb(73, 100, 241);
    -webkit-border-radius: 5px;
    border-radius: 5px;
}
.nestable-drag-layer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    pointer-events: none;
}
.nestable-rtl .nestable-drag-layer {
    left: auto;
    right: 0;
}
.nestable-drag-layer>.nestable-list {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    background-color: rgba(106, 127, 233, 0.274);
}
.nestable-rtl .nestable-drag-layer>.nestable-list {
    padding: 0;
}
.nestable [draggable="true"] {
    cursor: move;
}
.nestable-handle {
    display: inline;
}

.nestable {
    position: relative;
}
.nestable-rtl {
    direction: rtl;
}
.nestable .nestable-list {
    margin: 0;
    list-style-type: none;
}
.nestable-item .nestable-list,
.nestable-item-copy .nestable-list {
    margin-top: 10px;
}
.nestable-item.is-dragging .nestable-list {
    pointer-events: none;
}
.nestable-item.is-dragging * {
    opacity: 0;
    filter: alpha(opacity=0);
}
.nestable-item.is-dragging:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(106, 127, 233, 0.274);
    border: 1px dashed rgb(73, 100, 241);
    -webkit-border-radius: 5px;
    border-radius: 5px;
}
.nestable-drag-layer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    pointer-events: none;
}
.nestable-rtl .nestable-drag-layer {
    left: auto;
    right: 0;
}
.nestable-drag-layer>.nestable-list {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    background-color: rgba(106, 127, 233, 0.274);
}
.nestable-rtl .nestable-drag-layer>.nestable-list {
    padding: 0;
}
.nestable [draggable="true"] {
    color: rgba($primary, .7);
    cursor: move;
    &:hover{
        color: $primary;
    }
}
.nestable-handle {
    vertical-align: sub;
    display: inline;
}
.folder-template{
    &:hover{
        background-color: rgba($blue, .3);
        cursor: pointer;
        color: $dark;
    }
}
.admin-nestable-style{
    .folder-template{
        &:hover{
            background-color: transparent;
        }
    }
    .nestable-item-content {
        &:hover {
        background-color: rgba($blue, .2);
        border-radius: $border-radius-small;
        border-bottom: 1px solid transparent;
        }
    }
}
.chosen_template{
    background-color: rgba($blue, .8);
    color: $light;
    box-shadow: 0 .2rem $yellow;
    &:hover{
        background-color: rgba($blue, .9);
        color: $light;
    }
}
$container-padding: 15px;
.photo-navigator {
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    &.right-button,
    &.left-button {
        @include max-width-md {
            bottom: initial;
            left: initial;
            transform: translateY(-50%);
            top: 50%;
        }
    }
    &.right-button {
        @include max-width-md {
            right: $container-padding;
        }
    }
    &.left-button {
        @include max-width-md {
            left: $container-padding;
        }
    }
}
.today {
    background-color: var(--fc-today-bg-color, rgba(255, 220, 40, 0.15));
}
.photo-week {
    a {
        opacity: 0.9;
        &:hover {
            opacity: 1;
            text-decoration: none;
        }
    }
}
.photo-week-tag {
    width: 0.8rem;
    height: 0.8rem;
}
.photo-card {
    .photo {
        border-radius: $border-radius-small $border-radius-small 0 0;
    }
    .hide{
        display: none;
    }
    .photo-desc-actionbar {
        border-radius: 0 0 $border-radius-small $border-radius-small;
    }
    textarea.photo-desc {
        resize: none;
        border: $border-size-regular solid rgba(85, 85, 85, 0.1);
        border-radius: 0 0 $border-radius-small $border-radius-small !important;
        font-size: 0.8rem;
        &:focus {
            box-shadow: none;
            border-radius: 0 !important;
            background-image: linear-gradient(
                    to right,
                    rgba($blue-light, 0.1),
                    rgba($blue-light, 0.1)
                ),
                linear-gradient(to right, $light, $light);
            background-repeat: no-repeat, no-repeat;
            background-position: center, center;
        }
        &:hover {
            border: $border-size-regular solid rgba($blue-light, 0.4);
            background-image: linear-gradient(
                    to right,
                    rgba($blue-light, 0.1),
                    rgba($blue-light, 0.1)
                ),
                linear-gradient(to right, $light, $light);
            background-repeat: no-repeat, no-repeat;
            background-position: center, center;
        }
    }
}

$bootstrap-blue: rgb(0, 123, 255);
$bootstrap-daner: rgb(220, 53, 69);
.dropdown-item.active,
.dropdown-item:active,
a.text-danger:focus,
a.text-danger:hover {
    &.text-danger {
        background-color: rgba($bootstrap-daner, 0.1);
    }
}
.dropdown-item.active,
.dropdown-item:active,
a.text-primary:focus,
a.text-primary:hover {
    &.text-primary {
        background-color: rgba($bootstrap-blue, 0.1);
    }
}

// chosen
.chosen-container-active .chosen-choices {
    box-shadow: none !important;
}
.chosen-container-multi .chosen-choices {
    height: calc(1.5em + 0.75rem + 2px) !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    box-shadow: none;
    background-image: none !important;
    border: $border-size-regular solid rgba($grey, 0.4) !important;
    padding: 0.25rem 0.75rem !important;
    border-radius: $border-radius-regular !important;
    transition: $transition-02s !important;    
}
.chosen-container {
    &.chosen-container-active {
        color: #495057;
        background-color: #fff;
        border-color: #80bdff !important;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        border-radius: 0.25rem;
    }
}
.fc-view-harness {
    background-color: $light;
}
.modal-backdrop.show{
    background-color: rgba($blue, .9) !important;
}

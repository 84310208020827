$blue: #007bff;
$red: #e30045;
$orange: #e37b00;
$yellow: #e3c000;
$green: #00e300;
$bluegreen: #4ebcbc;
$violet: #e2b5ff;
.meeting-calendar {
    .fc-h-event {
        border: initial !important;
        background-color: initial !important;
    }
}
.tag-blue,
.tag-bluegreen,
.tag-green,
.tag-orange,
.tag-purple,
.tag-red,
.tag-yellow,
.tag-default {
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
}
.tag-default {
    background-color: var(--blue);
}
.tag-red {
    background-color: $red;
}
.tag-orange {
    background-color: $orange;
}
.tag-yellow {
    background-color: $yellow;
}
.tag-green {
    background-color: $green;
}
.tag-bluegreen {
    background-color: $bluegreen;
}
.tag-purple {
    background-color: $violet;
}
.tag-blue {
    background-color: $blue;
}
.daygrid-tag-default {
    a {
        color: var(--blue);
    }
    .fc-daygrid-event-dot {
        border-color: var(--blue);
    }
    &.fc-event-main-frame {
        border-color: var(--blue);
        background-color: rgba(#007bff, 0.8);
    }
}
.daygrid-tag-blue {
    a {
        color: $blue;
    }
    .fc-daygrid-event-dot {
        border-color: $blue;
    }
    .fc-h-event {
        border-color: $blue;
        background-color: rgba($blue, 0.8);
    }
    &.fc-event-main-frame{
        border-color: $blue;
        background-color: rgba($blue, 0.8);
    }
}
.daygrid-tag-red {
    a {
        color: $red;
    }
    .fc-daygrid-event-dot {
        border-color: $red;
    }
    .fc-h-event {
        border-color: $red;
        background-color: rgba($red, 0.8);
    }
    &.fc-event-main-frame{
        border-color: $red;
        background-color: rgba($red, 0.8);
    }
}
.daygrid-tag-orange {
    a {
        color: $orange;
    }
    .fc-daygrid-event-dot {
        border-color: $orange;
    }
    &.fc-event-main-frame {
        border-color: $orange;
        background-color: rgba($orange, 0.8);
    }
}
.daygrid-tag-yellow {
    a {
        color: darken($yellow, 10);
    }
    .fc-daygrid-event-dot {
        border-color: $yellow;
    }
    &.fc-event-main-frame {
        border-color: $yellow;
        background-color: rgba(darken($yellow, 10), 0.8);
    }
}
.daygrid-tag-green {
    a {
        color: darken($green, 10);
    }
    .fc-daygrid-event-dot {
        border-color: $green;
    }
    &.fc-event-main-frame {
        border-color: $green;
        background-color: rgba(darken($green, 10), 0.8);
    }
}
.daygrid-tag-bluegreen {
    a {
        color: darken($bluegreen, 15);
    }
    .fc-daygrid-event-dot {
        border-color: $bluegreen;
    }
    &.fc-event-main-frame {
        border-color: $bluegreen;
        background-color: rgba(darken($bluegreen, 10), 0.8);
    }
}
.daygrid-tag-purple {
    a {
        color: darken($violet, 30);
    }
    .fc-daygrid-event-dot {
        border-color: darken($violet, 10);
    }
    &.fc-event-main-frame {
        border-color: $violet;
        background-color: rgba(darken($violet, 20), 0.8);
    }
}
.tag-color-default {
    color: var(--blue);
}
.tag-color-red {
    color: $red;
}
.tag-color-orange {
    color: $orange;
}
.tag-color-yellow {
    color: darken($yellow, 10);
}
.tag-color-green {
    color: darken($green, 10);
}
.tag-color-bluegreen {
    color: darken($bluegreen, 10);
}
.tag-color-purple {
    color: darken($violet, 20);
}
#color_list_container > div.align-items-center {
    cursor: pointer;
}
#color_list_container > div.align-items-center:hover {
    background-color: rgba($blue, .2);
    border-radius: $border-radius-regular;
    transition: $transition-02s;
}
.is_default {
    background-color: rgba($blue, .1);
    border-radius: $border-radius-regular;
    transition: $transition-02s;
}

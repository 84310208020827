@import "_variable";
@import "_overwrite";
@import "_navbar";
@import "_tag";
@import "_form";
@import "_button";
@import "_bgcolor";
@import "_typography";
@import "_hover";
@import "_list";
@import "_dropdown";
@import "_photo";
@import "_login";
@import "_footer";
@import "_utility";
@import "_k-card";
@import "_k-chart";
@import "_k-position";
@import "_border";
@import "_time-management";
@import "_quote-contract";
@import "_nestable";
@import "_directory-list";
@import "_border";
@import "_time-management";
@import "_quote-contract";
:root{
    --primary: rgba(0, 101, 255);
    --secondary: rgba(85, 85, 85);
    --dark: rgba(23, 25, 67);
    --grey: rgba(85, 85, 85);
    --success: rgba(123, 212, 30);
    --warning: rgba(222, 151, 47);
    --danger: rgba(179, 31, 0);
    --yellow: rgba(249, 221, 0);
    --purple: rgba(69, 54, 154);
    --blue: rgba(0, 101, 255);
    --blue-light: rgba(17, 199, 255);
    --blue-dark: rgba(0, 45, 138);
    --transition-02s: 0.2s all;
}
html {
    height: 100%;
}
body {
    font-size: 14px;
    font-family: source-han-sans-traditional, "Noto Sans TC", "PingFang TC",
        Roboto, "Microsoft JhengHei", sans-serif;
    background-color: $light;
}
.layout {
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto auto 1fr;
}
.footer {
    grid-row-start: 4;
    grid-row-end: 5;
}
.disabled {
    cursor: not-allowed;
}
.bg-primary-o5 {
    background-color: #ddedff;
}
.z-index-highest {
    z-index: 1100;
}
.d-grid {
    display: grid;
}
.photo-list img {
    padding: 0.3rem;
    max-height: 300px;
}
.absolute--right-top {
    right: 0.2rem;
    top: 0.2rem;
}
.absolute--center-bottom {
    left: 50%;
    transform: translateX(-50%);
    bottom: 1rem;
}
.absolute---top-center{
    top: 50%;
    transform: translateY(-50%);
}
.absolute--align-bottom {
    bottom: 0;
}
.absolute--left-bottom {
    left: 1rem;
    bottom: 1rem;
}
.overlay {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(77, 143, 255, 0.9);
    z-index: 1200;
}
.brand img {
    width: 46px;
    height: 46px;
}
.white-space-normal {
    white-space: normal;
}
.white-space-nowrap {
    white-space: nowrap;
}
.btn-line-notify {
    background-color: #00c300;
    color: #fff;
}
.btn-line-notify:hover {
    background-color: #00b300;
    color: #fff;
}
.btn-line-notify img {
    width: 1rem;
    height: 1rem;
}
.photo-list {
    grid-gap: 1rem;
}
.stripe {
    & > *:nth-of-type(2n + 1) {
        background-color: #ebf5ff;
    }
}
a {
    &.wrap-link {
        color: $blue-dark;
        &:hover {
            color: $blue;
            text-decoration: none;
        }
    }
}
@media (min-width: 990px) {
    .width-110px-md {
        width: 110px;
    }
}
@media (max-width: 767px) {
    .bg-primary-o5-lg {
        background-color: #ddedff;
    }
    .brand img {
        width: 30px;
        height: 30px;
    }
    .project-title {
        font-size: 0.88rem;
    }
    .w-lg-100 {
        width: 100%;
    }
}
@media (min-width: 767px) {
    .h-lg-100 {
        height: 100% !important;
    }
    .d-table.d-table-striped .d-table-row:nth-of-type(2n + 1) {
        background-color: #ebf5ff;
    }
    .opacity-hover {
        opacity: 0.9;
    }
    .opacity-hover:hover {
        opacity: 1;
    }
    .dropdown-menu-right-lg {
        right: 0;
        left: auto;
    }
    .max-width-200px-lg {
        max-width: 200px;
    }
    .width-110px-lg {
        width: 110px;
    }
}
.photo-list {
    grid-template-columns: repeat(5, 1fr);
}
@media (max-width: 1300px) {
    .photo-list {
        grid-template-columns: repeat(4, 1fr);
    }
}
@media (max-width: 1000px) {
    .photo-list {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (max-width: 540px) {
    .photo-list {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 2rem;
    }
}
.box-shadow {
    box-shadow: 0 0.2rem 0.5rem rgba($dark, 0.2);
}
.transition-1x {
    transition: $transition-02s;
}
.animate-to-up {
    transform: translateY(-100%);
}
.avatar {
    width: 40px;
    height: 40px;
}
.avatar-sm {
    width: 30px;
    height: 30px;
}
.k-overlayer-inner {
    width: 300px;
    left: -50%;
    top: 0;
    bottom: 0;
    background-color: $light;
    z-index: 1030;
}
.k-overlayer-outer {
    display: grid;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    align-items: center;
    pointer-events: none;
    opacity: 0;
    &.open {
        opacity: 1;
        pointer-events: all;
        .k-overlayer-inner {
            left: 0;
        }
    }
}
.z-index-1030 {
    z-index: 1030;
}
@include min-width-sm() {
    .h-100vh-sm{
        height: 100vh;
    }
}
.step {
    $stepper-width: 20px;
    $stepper-heght: 50px;
    .step-background{
        background-color: rgba($grey, .1);
    }
    &:before{
        width: $stepper-width;
        height: $stepper-heght;
        content: ' ';
        display: block;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='20' height='50' viewBox='0 0 20 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='1152' height='800' transform='translate(-219 -182)' fill='white'/%3E%3Cpath opacity='0.1' d='M1.41051 13.6782C-1.1857 7.1148 3.6512 0 10.7095 0H424.313C428.416 0 432.102 2.50634 433.612 6.32174L439.545 21.3217C440.48 23.6848 440.48 26.3152 439.545 28.6783L433.612 43.6783C432.102 47.4937 428.416 50 424.313 50H10.7095C3.65119 50 -1.1857 42.8852 1.41051 36.3218L4.43394 28.6782C5.36867 26.3152 5.36867 23.6848 4.43394 21.3217L1.41051 13.6782Z' fill='%23555555'/%3E%3C/svg%3E");
    }
    &:after{
        width: $stepper-width;
        height: $stepper-heght;
        content: ' ';
        display: block;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='20' height='50' viewBox='0 0 20 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='1152' height='800' transform='translate(-640 -182)' fill='white'/%3E%3Cpath opacity='0.1' d='M-419.589 13.6782C-422.186 7.1148 -417.349 0 -410.291 0H3.31276C7.4158 0 11.1025 2.50634 12.6117 6.32174L18.545 21.3217C19.4798 23.6848 19.4798 26.3152 18.545 28.6783L12.6117 43.6783C11.1025 47.4937 7.41578 50 3.31274 50H-410.291C-417.349 50 -422.186 42.8852 -419.589 36.3218L-416.566 28.6782C-415.631 26.3152 -415.631 23.6848 -416.566 21.3217L-419.589 13.6782Z' fill='%23555555'/%3E%3C/svg%3E");
    }
    &.warning{
        .step-background{
            background-color: rgba($warning, .1);
        }
        &:before{
            width: $stepper-width;
            height: $stepper-heght;
            content: ' ';
            display: block;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='20' height='50' viewBox='0 0 20 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='1152' height='800' transform='translate(-675 -182)' fill='white'/%3E%3Cpath opacity='0.1' d='M0.410506 13.6782C-2.1857 7.1148 2.6512 0 9.70946 0H423.313C427.416 0 431.102 2.50634 432.612 6.32174L438.545 21.3217C439.48 23.6848 439.48 26.3152 438.545 28.6783L432.612 43.6783C431.102 47.4937 427.416 50 423.313 50H9.70945C2.65119 50 -2.1857 42.8852 0.410507 36.3218L3.43394 28.6782C4.36867 26.3152 4.36867 23.6848 3.43394 21.3217L0.410506 13.6782Z' fill='%23DE972F'/%3E%3C/svg%3E");
        }
        &:after{
            width: $stepper-width;
            height: $stepper-heght;
            content: ' ';
            display: block;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='20' height='50' viewBox='0 0 20 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='1152' height='800' transform='translate(-1096 -182)' fill='white'/%3E%3Cpath opacity='0.1' d='M-420.589 13.6782C-423.186 7.1148 -418.349 0 -411.291 0H2.31276C6.4158 0 10.1025 2.50634 11.6117 6.32174L17.545 21.3217C18.4798 23.6848 18.4798 26.3152 17.545 28.6783L11.6117 43.6783C10.1025 47.4937 6.41578 50 2.31274 50H-411.291C-418.349 50 -423.186 42.8852 -420.589 36.3218L-417.566 28.6782C-416.631 26.3152 -416.631 23.6848 -417.566 21.3217L-420.589 13.6782Z' fill='%23DE972F'/%3E%3C/svg%3E");
        }
        .step-counter{
            color: darken($warning, 5);
        }
    }
    &.done{
        .step-background{
            background-color: rgba($success, .1);
        }
        &:before{
            width: $stepper-width;
            height: $stepper-heght;
            content: ' ';
            display: block;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='20' height='50' viewBox='0 0 20 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='-474' y='-56' width='930' height='161' rx='10' fill='white'/%3E%3Cpath opacity='0.1' d='M1.41051 13.6782C-1.1857 7.1148 3.6512 0 10.7095 0H424.313C428.416 0 432.102 2.50635 433.612 6.32175L439.545 21.3218C440.48 23.6848 440.48 26.3152 439.545 28.6783L433.612 43.6783C432.102 47.4937 428.416 50 424.313 50H10.7095C3.65119 50 -1.1857 42.8852 1.41051 36.3218L4.43394 28.6782C5.36867 26.3152 5.36867 23.6848 4.43394 21.3217L1.41051 13.6782Z' fill='%2357AB00'/%3E%3C/svg%3E");
        }
        &:after{
            width: $stepper-width;
            height: $stepper-heght;
            content: ' ';
            display: block;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='20' height='50' viewBox='0 0 20 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='-895' y='-56' width='930' height='161' rx='10' fill='white'/%3E%3Cpath opacity='0.1' d='M-419.589 13.6782C-422.186 7.1148 -417.349 0 -410.291 0H3.31277C7.41582 0 11.1025 2.50635 12.6117 6.32175L18.545 21.3218C19.4798 23.6848 19.4798 26.3152 18.545 28.6783L12.6117 43.6783C11.1025 47.4937 7.41579 50 3.31275 50H-410.291C-417.349 50 -422.186 42.8852 -419.589 36.3218L-416.566 28.6782C-415.631 26.3152 -415.631 23.6848 -416.566 21.3217L-419.589 13.6782Z' fill='%2357AB00'/%3E%3C/svg%3E");
        }
        .step-counter{
            color: darken($success, 5);
        }

    }
}
.period{
    &:after{
        display: inline-block;
        content: ".";
    }
}
.vertical-line{
    padding-left: 2rem;
    &:before{
        content: '';
        display: block;
        width: 2px;
        position: absolute;
        top: 20px;
        bottom: 20px;
        left: 1rem;
        background-color: rgba($grey, .2);
    }
    li{
        position: relative;
        &:before{
            content: "\f105";
            font-family: "Font Awesome 5 Free"; font-weight: 900; 
            display: flex;
            align-items: center;
            justify-content: center;
            background-image: linear-gradient(rgba($grey, .2), rgba($grey, .2)),
                linear-gradient(rgba(255,255,255,1), rgba(255,255,255,1));
            border-radius: 100%;
            width: 1.4rem;
            height: 1.4rem;
            padding: .1rem;
            position: absolute;
            top: 50%;
            left: -1.6rem;
            transform: translateY(-50%);
        }
        &.warning{
            &:before{
                content:'\f04c';
                color: darken($warning, 5);
                background-image: linear-gradient(rgba($warning, .3), rgba($warning, .3)),
                linear-gradient(rgba(255,255,255,1), rgba(255,255,255,1));
            }            
        }
        &.done{
            &:before{
                content:'\f00c';
                color: darken($success, 5);
                background-image: linear-gradient(rgba($success, .3), rgba($success, .3)),
                linear-gradient(rgba(255,255,255,1), rgba(255,255,255,1));
            }            
        }
    }
}
.project-title{
    @media all and (max-width: 401px){
        max-width: 150px;
    }
}
#searchStickyTop{
    @media all and (max-width: 401px){
        top: 40px;
    }
}
.sticky-md-top{
    @media all and (min-width: 1025px){
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}

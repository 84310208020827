.seperate-dot{
    .seperate-dot-item{
        &:after{
            content:'';
            background-color: rgba($grey-dark, .3);
            display: inline-block;
            width: .5rem;
            height: .5rem;
            border-radius: 100%;
        }
        &:last-child{
            &:after{
                display: none;
            }
        }
    }
}
.border-radius-large{
    border-radius: $border-radius-large;
}
.border-radius-regular{
    border-radius: $border-radius-regular;
}
.border-radius-small{
    border-radius: $border-radius-small;
}
.hide{
    display: none;
}
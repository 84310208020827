.navbar {
    [aria-expanded="true"] {
        background-color: $grey-light;
        color: $grey-dark;
    }
    .active[aria-expanded="true"] {
        background-color: $grey-light;
        color: $blue;
    }
    button,
    a.btn {
        font-size: 1rem;
        color: $grey-dark;
        border-radius: $border-radius-regular;
        opacity: 0.8;
        transition: $transition-02s;
        background-color: rgba($grey-light, 0.4);
        &.active {
            background-color: $grey-light;
            opacity: 1;
            color: $blue;
        }
        &:hover {
            background-color: $grey-light;
            opacity: 1;
            color: $grey-dark;
        }
        &:focus {
            box-shadow: initial;
        }
        a [aria-expanded="true"] {
            color: $blue;
        }
    }
    a {
        color: $grey-dark;
        transition: $transition-02s;
        &:hover {
            color: $blue;
            text-decoration: none;
        }
        &.active {
            color: $blue;
        }
    }
}
#projectnav_scroll {
    &.sticky-top {
        padding-top: 30px;
    }
}

// Color
$yellow: #F9DD00;
$purple: #45369A;
$blue-light: #11C7FF;
$blue: #0065FF;
$blue-dark: #002D8A;
$dark: #171943;
$grey: #555555;
$grey-dark: #333333;
$grey-light: #EEEEEE;
$light: #FFFFFF;
// Functional color
$primary: $blue;
$warning: #DE972F;
$danger: #E82800;
$success: #57AB00;
// Size
$border-radius-large: 2rem;
$border-radius-regular: 0.9rem;
$border-radius-small: 0.45rem;
$padding-regular: 0.42rem 1rem;
$padding-x-large: 0.5rem 2rem;
$border-size-large: 0.3rem;
$border-size-regular: 0.14rem;
// Animation
$transition-02s: 0.2s all;
$colors: (
    "yellow": $yellow,
    "blue": $blue,
    "purple": $purple,
    "blue-light": $blue-light,
    "blue-dark": $blue-dark,
    "dark": $dark,
    "grey": $grey,
    "grey-dark": $grey-dark,
    "grey-light": $grey-light,
    "light": $light,
    "warning": $warning,
    "danger": $danger,
    "success": $success
);
// Screen width
$width-xl: 1960px;
$width-lg: 992px;
$width-md: 768px;
$width-sm: 540px;
@mixin max-width-xl() {
    @media all and (max-width: $width-xl) {
        @content;
    }
}
@mixin max-width-lg() {
    @media all and (max-width: $width-lg) {
        @content;
    }
}
@mixin max-width-md() {
    @media all and (max-width: $width-md) {
        @content;
    }
}
@mixin max-width-sm() {
    @media all and (max-width: $width-sm) {
        @content;
    }
}
@mixin min-width-sm() {
    @media all and (min-width: $width-sm) {
        @content;
    }
}
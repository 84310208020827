input[type="text"],
input[type="password"],
input[type="date"],
input[type="number"],
input[type="email"],
select {
    box-shadow: initial;
    width: 100%;
    &:focus {
        outline: 0;
        box-shadow: initial;
        border: $border-size-regular solid rgba($blue-light, 0.4);
        background-image: linear-gradient(
                to right,
                rgba($blue-light, 0.1),
                rgba($blue-light, 0.1)
            ),
            linear-gradient(to right, $light, $light);
        background-repeat: no-repeat, no-repeat;
        background-position: center, center;
    }
    &::placeholder {
        color: rgba($grey-dark, 0.7);
    }
}
.form-control {
    border: $border-size-regular solid rgba($grey, 0.4);
    padding: 0.3rem 1rem 0.4rem;
    border-radius: $border-radius-regular !important;
    transition: $transition-02s;
}
.input-group-text {
    display: flex;
    border-radius: 13px 0 0 13px;
    position: absolute;
    z-index: 1;
    padding: 0.4rem 0.5rem;
}
.input-group {
    border: $border-size-regular solid rgba($grey, 0.4);
    border-radius: $border-radius-regular !important;
    overflow: hidden;
    label {
        margin-bottom: 0;
    }
    input {
        margin-right: 0.5rem;
    }
    .form-control {
        border: none;
        margin-left: 4.3rem;
        border-radius: 0 !important;
    }
}
.custom-file-label::after {
    content: "選擇檔案...";
    height: 38px;
}
.custom-file-label {
    border: $border-size-regular solid rgba($grey, 0.4);
    border-radius: $border-radius-regular !important;
    overflow: hidden;
    left: 2px;
    right: 8px;
}
.custom-file,
.custom-file-input,
.custom-file-label {
    height: 38px;
    padding: $padding-regular;
}
.click-to-edit {
    border: initial;
    padding-left: 0;
    padding-right: 0;
    &:focus {
        padding-left: 1rem;
        padding-right: 1rem;
        background-image: linear-gradient(
                to right,
                rgba($blue-light, 0.1),
                rgba($blue-light, 0.1)
            ),
            linear-gradient(to right, $light, $light);
    }
}
.search-icon {
    &::before {
        content: "\f002";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        font-size: 0.8rem;
        text-rendering: auto;
        position: absolute;
        left: 0.5rem;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        color: rgba($grey, 0.4);
    }
}
.select-radio {
    input[type="radio"]{
        display: none;
    }
    .select-radio-item ~ label {
        border: $border-size-large solid transparent;
    }
    .select-radio-item:checked ~ label {
        border: $border-size-large solid rgba($primary, 0.4);
        border-radius: $border-radius-small;
    }
}

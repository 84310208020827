@each $color, $i in $colors {
    .dashed-border-#{$color} {
        border: .16rem dashed $i;
    }
}
@each $color, $i in $colors {
    $opacityNum: 0;
    $opacityName: 0;
    @while $opacityName < 10 {
        .dashed-border-#{$color}-o#{$opacityName} {
            border: .16rem dashed rgba($i, $opacityNum);
        }
        $opacityNum: $opacityNum + 0.1;
        $opacityName: $opacityName + 1;
    }
}
@each $color, $i in $colors {
    .border-#{$color} {
        border: .16rem solid $i;
    }
}

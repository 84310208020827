.absolute-vertical-center {
    top: 50% !important;
    transform: translateY(-50%) !important;
}
.absolute-center {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}
.position-lg-fixed {
    @media all and(min-width: 1025px) {
        position: fixed !important;
    }
}
